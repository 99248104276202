<template>
  <b-row>
    <b-col sm="12" v-if="youtubeData.subscribers_count">
      <b-card no-body>
        <b-card-header>
          {{$t('campaigns.followers')}} {{getFormat(this.youtubeData.subscribers_count.value)}}
        </b-card-header>
        <b-card-body>
          <component :is="apex_charts" type="line" height="300" :options="chartOptions.subscribers" :series="series.subscribers"></component>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col sm="12" md="6" lg="6" v-if="youtubeData.views_count">
      <b-card no-body>
        <b-card-header>{{$t('youtube.views')}} {{getFormat(this.youtubeData.views_count.value)}}</b-card-header>
        <b-card-body>
          <component :is="apex_charts" type="line" height="300" :options="chartOptions.views" :series="series.views"></component>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col sm="12" md="6" lg="6" v-if="youtubeData.media_count">
      <b-card no-body>
        <b-card-header>{{$t('youtube.contents')}} {{getFormat(this.youtubeData.media_count.value)}}</b-card-header>
        <b-card-body>
        <component :is="apex_charts" type="line" height="300" :options="chartOptions.media" :series="series.media"></component>
      </b-card-body>
      </b-card>

    </b-col>
  </b-row>
</template>
<script>
import {
  BCard, BCardHeader,  BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import { getDate } from '@/libs/utils/times';
import { getFormat, convertData } from '@/libs/utils/formats';
import { getSeries } from '@/libs/utils/series_graphs';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
  },
  props: {
    youtubeData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      getFormat,
      chartOptions: {
        subscribers: {
          chart: {
            zoom: {
              enabled: true,
            },
            toolbar: {
              show: true,
              offsetY: -20,
              tools: {
                download: false,
                selection: false,
                zoom: '<b-icon/>',
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: `<img src="${require('@/assets/images/icons/reset.png')}" width="20">`
              }
            },
            type: 'line',
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10
          },
          stroke: {
            curve: 'smooth',
            width: 4,
          },
          grid: {
            borderColor: '#ebe9f1',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          markers: {
            size: 0,
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: ['#6c84e0'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          dataLabels: {
            enabled: false
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter(val) {
                if (val >= 1000000) return `${val / 1000000}M`
                if (val >= 1000) return `${val / 1000}K`
                return val
              },
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: false,
            },
            type: 'datetime',
            categories: this.youtubeData.subscribers_count.history.map(e => { return getDate(e.time) })
          },
          colors: ['#df87f2']
        },
        media: {
          chart: {
            zoom: {
              enabled: true,
            },
            toolbar: {
              show: true,
              offsetY: -20,
              tools: {
                download: false,
                selection: false,
                zoom: '<b-icon/>',
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: `<img src="${require('@/assets/images/icons/reset.png')}" width="20">`
              }
            },
            type: 'line',
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10
          },
          stroke: {
            curve: 'smooth',
            width: 4,
          },
          grid: {
            borderColor: '#ebe9f1',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          markers: {
            size: 0,
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: ['#6c84e0'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: false,
            },
            type: 'datetime',
            categories: this.youtubeData.media_count.history.map(e => { return getDate(e.time) }).reverse()
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter(val) {
                if (val >= 1000000) return `${Math.round(val / 1000000)}M`
                if (val >= 1000) return `${Math.round(val / 1000)}K`
                return val
              },
            },
          },
          colors: ['#df87f2']
        },
        views: {
          chart: {
            zoom: {
              enabled: true,
            },
            toolbar: {
              show: true,
              offsetY: -20,
              tools: {
                download: false,
                selection: false,
                zoom: '<b-icon/>',
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: `<img src="${require('@/assets/images/icons/reset.png')}" width="20">`
              }
            },
            type: 'line',
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10
          },
          stroke: {
            curve: 'smooth',
            width: 4,
          },
          grid: {
            borderColor: '#ebe9f1',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          markers: {
            size: 0,
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: ['#6c84e0'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          dataLabels: {
            enabled: false
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter(val) {
                if (val >= 1000000) return `${Math.round(val / 1000000)}M`
                if (val >= 1000) return `${Math.round(val / 1000)}K`
                return val
              },
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: false,
            },
            type: 'datetime',
            categories: this.youtubeData.views_count ? this.youtubeData.views_count.history.map(e => { return getDate(e.time) }) : []
          },
          colors: ['#df87f2']
        },
      },
      series: {
        subscribers: [{name: `${this.$t('campaigns.followers')}`, data: []}],
        media: [{name: `${this.$t('youtube.media')}`, data: []}],
        views: [{name: `${this.$t('youtube.views')}`, data: []}],
      },
      apex_charts: null,
    }
  },
  methods: {
    quitAll(data) {
      const arr = convertData(data)
      arr.pop()
      return arr
    },
    getGender(data) {
      const obj = []
      const keys = Object.keys(data)
      for (let index = 0; index < keys.length; index++) {
        obj.push({male: data[keys[index]].male, female: data[keys[index]].female})
      }
      return obj
    }
  },
  created () {
    this.series.subscribers[0].data = getSeries(this.youtubeData.subscribers_count.history, 'value')
    this.series.media[0].data = getSeries(this.youtubeData.media_count.history, 'value')
    this.series.views[0].data = this.youtubeData.views_count ? getSeries(this.youtubeData.views_count.history, 'value') : []

  },
  async mounted() {
    this.apex_charts = (await import('vue-apexcharts')).default;
  },
  watch: {
    '$i18n.locale'() {
      this.series.subscribers = [{name: `${this.$t('campaigns.followers')}`}];
      this.series.views =  [{name: `${this.$t('youtube.views')}`}];
      this.series.media =  [{name: `${this.$t('youtube.media')}`}];
    }
  }
}
</script>
